<template>
  <div class="bigScreen">
    <dv-loading v-if="loading">Loading...</dv-loading>

    <dv-full-screen-container class="screen-container clearfix">
      <div class="top">
        <div class="title">
          <span class="title-text">{{ name }}汽配汽修商家分布图</span>
        </div>

        <div class="time-div">
          <p class="time">{{ dateDay }}</p>
          <div>
            <p>{{ dateWeek }}</p>
            <p>{{ dateYear }}</p>
          </div>
        </div>
      </div>

      <div class="main">
        <div class="left">
          <div>
            <div class="back-btn" style="color: #6eadea;font-size: 18px;" v-if="backTo" @click="toReturn()">
              <v-icon color="#6eadea" style="font-size: 24px;margin-right: 4px;">
                mdi-arrow-left
              </v-icon>
              返回
            </div>
            <div class="big-title">数据总览</div>
            <div class="data-view">
              <dv-decoration-9 class="total-num" style="width:200px;height:200px;" :dur="12">
                <div class="store-p">
                  <p>{{ totalData.total }}</p>
                  <span>商家总数</span>
                </div>
              </dv-decoration-9>

              <div class="classify">
                <div class="classify-item">
                  <img src="@/assets/images/bigScreen/shengchan.png" alt="">
                  <span class="name">生产制造</span>
                  <span class="num">{{ totalData.shengchan }}</span>
                </div>
                <div class="classify-item">
                  <img src="@/assets/images/bigScreen/jingxiao.png" alt="">
                  <span class="name">经销批发</span>
                  <span class="num">{{ totalData.jingxiao }}</span>
                </div>
                <div class="classify-item">
                  <img src="@/assets/images/bigScreen/weixiu.png" alt="">
                  <span class="name">维修养护</span>
                  <span class="num">{{ totalData.weixiu }}</span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="big-title">商家数量排名</div>
            <div class="rank-view">
              <dv-scroll-ranking-board :config="config" style="height:100%;width: 100%;" />
            </div>
          </div>
        </div>
        <div class="map-p">
          <div class="img-p">
            <img src="./bgMap.png" alt="">
          </div>
          <div id="mapChart" style="width: 100%;height: 100%;"></div>
        </div>
      </div>
      <div class="scanCode">
        <div class="img">
          <img src="@/assets/images/index/dialogCodeX2.png" alt="">
        </div>
        <span>扫码查看更多</span>
      </div>

      <v-overlay :value="dialogShow" :z-index="9999">
        <v-card class="code-dialog-card clearfix" light>
          <p>查看更多商家信息请扫描下方二维码</p>
          <v-icon class="close-icon" color="#666" right @click="dialogShow = false">mdi-window-close</v-icon>
          <img src="@/assets/images/index/dialogCodeX2.png" alt="">
          <span>打开微信扫一扫</span>
        </v-card>
      </v-overlay>
    </dv-full-screen-container>
  </div>
</template>
<script>
import { formatTime } from '../../utils/index.js'

export default {
  metaInfo() {
    return {
      title: "汽车配件商家分布可视化大屏-配友软件",
      // meta: [
      //   { charset: "utf-8" },
      //   { name: "description", content: "配友软件汽车配件商家数据可视化大屏，多维度视觉化展现全国汽配商家分布，助力企业从宏观把控全国汽配业态势，精准决策拓展策略。" },
      // ],
    };
  },
  data() {
    return {
      dialogShow: false,
      myEcharts: "",
      config: {
        data: [],
        unit: '',
        valueFormatter({ value, name, percent, ranking }) {
          // console.log(value, name, percent, ranking);
          // const reverseNumber = (value + '').split('').reverse()
          // let valueStr = ''

          // while (reverseNumber.length) {
          //   const seg = reverseNumber.splice(0, 3).join('')
          //   valueStr += seg
          //   if (seg.length === 3) valueStr += ','
          // }
          return value
        }
      },
      loading: false,

      mapData: '',

      totalData: {
        total: "",
        shengchan: "",
        jingxiao: "",
        weixiu: "",
      },

      timing: null,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],

      backTo: false,
      name: '全国',
    }
  },
  created() {
    document.querySelector('meta[name="description"]').setAttribute('content', "配友软件汽车配件商家数据可视化大屏，多维度视觉化展现全国汽配商家分布，助力企业从宏观把控全国汽配业态势，精准决策拓展策略")
  },
  mounted() {
    this.timeFn()
    this.getData()
    setTimeout(() => {
      if (document.querySelector(".v-overlay__scrim")) {
        document.querySelector(".v-overlay__scrim").addEventListener("click", () => {
          this.dialogShow = false;
        })
      }
    }, 500);
  },
  beforeDestroy() {
    if (this.timing) {
      clearInterval(this.timing)
    }
  },
  methods: {
    timeFn() {
      this.dateDay = formatTime(new Date(), 'HH: mm: ss');
      this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
      this.dateWeek = this.weekday[new Date().getDay()];

      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss');
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    // 返回
    toReturn() {
      this.loading = true; // 省级页面下点击返回长时间无跳转响应
      this.getData('0');
    },
    getData(id = "0") {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.$http({
          url: this.$store.state.requestUrl + "/v1.1/statistics_screen",
          method: 'get',
          params: {
            id: id,
          }
        }).then(res => {
          if (res.data.code === 0) {
            let need = res.data.data;
            this.totalData = {
              total: need.total,
              shengchan: need.shengchan,
              jingxiao: need.jingxiao,
              weixiu: need.weixiu,
            }
            this.mapData = need.data;
            this.config.data = need.data.slice(0, 6);
            this.config = { ...this.config };
            this.loading = false;
            this.initEcharts(id)
            resolve()
          } else {
            this.loading = false;
            reject()
          }
        }).catch(err => {
          this.loading = false;
          reject()
          console.log(err);
        })
      })
    },
    initEcharts(mapName) {
      let map;
      if (mapName === "0") {
        this.name = "全国";
        this.backTo = false;
        map = require("./mapJson/000000.json")
      } else {
        this.backTo = true;
        map = require("./mapJson/" + mapName + ".json")
      }

      this.myEcharts = this.$echarts.init(document.getElementById('mapChart'));

      let data = this.mapData;

      /*获取地图数据*/
      this.myEcharts.showLoading();
      if (mapName === "0") {
        mapName = 'china'
      }
      this.$echarts.registerMap(mapName, map);
      var mapFeatures = this.$echarts.getMap(mapName).geoJson.features;

      this.myEcharts.hideLoading();

      this.myEcharts.on('click', (params) => {
        // console.log(params);
        if (params.data && params.data.id && !this.backTo) {
          this.name = params.name;
          this.myEcharts.dispose()
          this.getData(params.data.id)
        } else {
          if(this.backTo){
            this.dialogShow = true;
          }
        }
      });

      var geoCoordMap = {};

      mapFeatures.forEach(function (v) {
        // console.log(v);
        // 地区名称
        var name = v.properties.name;
        // 地区id
        var id = v.id;
        // 地区经纬度
        // geoCoordMap[name] = v.properties.cp;
        geoCoordMap[id] = v.properties.cp;
      });

      var convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          // console.log(data[i]);
          // var geoCoord = geoCoordMap[data[i].name]; // 点显示的经纬度
          var geoCoord = geoCoordMap[data[i].id]; // 点显示的经纬度
          if (geoCoord) {
            res.push({
              name: data[i].name, // 显示省份名称
              id: data[i].id, // 显示省份名称
              value: geoCoord.concat(data[i].value), // 显示点
              num: data[i].num
            });
          }
        }
        return res;
      };

      let option = {
        tooltip: {
          padding: 0,
          enterable: true,
          transitionDuration: 1,
          backgroundColor: 'transparent',
          formatter: function (params) {

            if (params.data && params.data.num) {
              var tipHtml = '';

              if (params.dimensionNames.length && params.dimensionNames.length > 1) {
                tipHtml = `<div class="custom-tooltip" >
                            <div class="provice-name"><span class="text-name title-item">${params.name} </span> <span class="num-totla">(${params.value[2]})</span></div>
                            <div class="classify-tip">生产制造：${params.data.num.shengchan}</div>
                            <div class="classify-tip">经销批发：${params.data.num.jingxiao}</div>
                            <div class="classify-tip">维修养护：${params.data.num.weixiu}</div>
                          </div>`
              } else {
                tipHtml = `<div class="custom-tooltip" >
                            <div class="provice-name"><span class="text-name title-item">${params.name} </span> <span class="num-totla">(${params.value})</span></div>
                            <div class="classify-tip">生产制造：${params.data.num.shengchan}</div>
                            <div class="classify-tip">经销批发：${params.data.num.jingxiao}</div>
                            <div class="classify-tip">维修养护：${params.data.num.weixiu}</div>
                          </div>`
              }
              return tipHtml;
            } else {
              return
              // return params.name
            }
          }
        },

        geo: {
          show: true,
          map: mapName,
          nameProperty: 'ID',
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: false,
            }
          },
          roam: false,
          itemStyle: {
            normal: {
              areaColor: '#004f7e', // 地图颜色
              borderColor: '#00a5c3', //地图轮廓颜色
            },
            emphasis: {
              areaColor: '#17d2e7', // 选中区域颜色
            }
          }
        },
        series: [
          {
            type: 'map',
            map: mapName,
            geoIndex: 0,
            aspectScale: 0.75, //长宽比
            roam: true,
            animation: false,
            data: data
          }, {
            name: '散点',
            type: 'scatter',
            coordinateSystem: 'geo',
            data: convertData(data),
            symbolSize: function (val) {
              // return val[2] / 1000;
              return 10; // 白点大小
            },
            label: {
              normal: {
                formatter: '{b}',
                position: 'right',
                show: true,
                color: "#fff"
              },
              emphasis: {
                show: true
              }
            },
            itemStyle: {
              normal: {
                color: '#fff',
                shadowColor: '#fff',
              }
            },
            zlevel: 66
          },
          {
            name: '',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            data: convertData(data),
            symbolSize: function (val) {
              // return val[2] / 1000;
              return 10; // 黄点大小
            },
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke'
            },
            hoverAnimation: true,
            itemStyle: {
              normal: {
                color: '#f5c53b',
                // color: 'red',
                shadowBlur: 10,
                shadowColor: '#f5c53b',
                // shadowColor: 'red'
              }
            },
            emphasis: {
              itemStyle: {
                color: '#f5c53b' // 高亮颜色
              }
            },
            zlevel: 99
          },
        ],
        visualMap: {
          type: 'continuous',
          text: ['多', '少'],
          showLabel: true,
          right: 'right',
          top: 'center',
          min: 0,
          max: data[0].value,
          inRange: {
            // color: ['#0084a6', '#006b81', '#0274b8', '#004f7e', '#004357',],
            color: ['#119fb6', '#004269',]
          },
          splitNumber: 100,
          textStyle: {
            color: '#fff'
          },
          seriesIndex: 0,
          zlevel: 55
        },
      };

      this.myEcharts.setOption(option);
    },
  }
}
</script>
<style>
html {
  overflow-y: auto;
}
</style>
<style lang="scss" scoped>
:deep.bigScreen {
  background-image: url('./bg.jpg');
  color: #fff;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;

  #dv-full-screen-container {
    padding: 10px;
  }

  .dv-loading {
    position: absolute;
    z-index: 999999;
  }

  .top {
    width: 100%;
    height: 80px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 60px;
  }

  .title {
    position: relative;
    width: 100%;
    height: 42px;
    text-align: center;

    .title-text {
      font-size: 36px;
      line-height: 36px;
      font-weight: bold;
      letter-spacing: 4px;
      height: 100%;
      background-image: linear-gradient(to bottom, #f1f9ff, #3e85d2);
      color: transparent;
      -webkit-background-clip: text;
    }

  }

  .time-div {
    position: absolute;
    right: 50px;
    top: 24px;
    display: flex;

    .time {
      font-size: 33px;
      line-height: 36px;
    }

    &>div {
      margin-left: 10px;

      p {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 0;
      }
    }
  }

  .main {
    width: 100%;
    height: calc(100% - 140px);
    display: flex;
    justify-content: center;
    align-items: center;

    .left {
      width: 500px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      position: relative;

      &>div {
        position: relative;
      }

      .back-btn {
        position: absolute;
        left: 0;
        top: -60px;
        z-index: 6;
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .big-title {
        width: 100%;
        height: 60px;
        text-indent: 4em;
        line-height: 60px;
        background: url('./title_bg.svg') no-repeat center center;
        background-size: cover;
        margin-bottom: 15px;

        &:first-child {
          margin-bottom: 20px;
        }
      }

      .data-view {
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .total-num {
          text-align: center;

          p {
            font-size: 20px;
            font-weight: 700;
            line-height: 20px;
            margin-bottom: 8px;
            background-image: linear-gradient(to bottom, #f1f9ff, #3e85d2);
            color: transparent;
            -webkit-background-clip: text;
          }

          span {
            font-size: 16px;
            line-height: 16px;
          }
        }

        .classify {
          font-size: 22px;

          .classify-item {
            display: flex;
            align-items: center;

            &:nth-child(2) {
              margin: 15px 0;
            }

            .name {
              margin: 0 15px 0 15px;
              letter-spacing: 1px;
              white-space: nowrap;
            }

            .num {
              min-width: 90px;
              font-weight: 700;
              background-image: linear-gradient(to bottom, #f1f9ff, #3e85d2);
              color: transparent;
              -webkit-background-clip: text;
            }
          }
        }
      }

      .rank-view {
        height: 380px;
        display: flex;
        justify-content: center;

        .dv-scroll-ranking-board .ranking-column .inside-column {
          height: 14px;
          border-radius: 4px;
          background-color: transparent;
          background-image: linear-gradient(90deg,
              #1099fb 0%,
              #1de0f6 100%);
        }

        .dv-scroll-ranking-board .ranking-column .shine {
          height: 4px;
          top: 5px;
        }

        .dv-scroll-ranking-board .ranking-column {
          border-bottom: 0;
          background-color: rgba($color: #0d2346, $alpha: 0.8);
          border-radius: 5px;
        }
      }
    }

    .map-p {
      width: 60%;
      height: 100%;
      position: relative;

      .img-p {
        position: absolute;
        width: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @-webkit-keyframes rotation {
        from {
          -webkit-transform: rotate(0deg);
        }

        to {
          -webkit-transform: rotate(360deg);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        transform: rotate(360deg);
        animation: rotation 36s linear infinite;
        -moz-animation: rotation 36s linear infinite;
        animation: rotation 36s linear infinite;
        -o-animation: rotation 36s linear infinite;
      }
    }
  }

  .scanCode {
    position: absolute;
    right: 30px;
    bottom: 60px;
    width: 127px;
    text-align: center;

    .img {
      width: 100%;
      height: 127px;
      padding: 7px;
      border-radius: 4px;
      background-color: #113f57;
      margin-bottom: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    span {
      font-family: PingFang-SC-Regular;
      font-size: 18px;
      line-height: 22px;
      color: #aae8f7;
    }
  }

  .code-dialog-card {
    width: 500px;
    position: relative;
    padding-top: 60px;
    overflow: hidden;
    user-select: none;
    font-size: 18px;
    color: #222222;
    text-align: center;

    p {
      font-size: 16px;
      margin-bottom: 40px;
    }

    span {
      padding: 50px 0 60px;
      display: block;
    }

    .close-icon {
      position: absolute;
      right: 0;
      top: 0;
      padding: 6px;
      cursor: pointer;
    }

    img {
      width: 250px;
      height: 250px;
      margin: 0 auto 0;
      display: block;
    }
  }

  .custom-tooltip {
    width: 240px;
    height: 144px;
    background: url("./tooltips_bg.svg") no-repeat center center;
    background-size: cover;
    padding: 20px 30px;

    .provice-name {
      height: 20px;
      font-family: DingTalk-JinBuTi;
      display: flex;
      margin-bottom: 15px;
      font-weight: 700;
      line-height: 20px;

      .text-name {
        font-size: 20px;
        color: #f5da4d;
      }

      span.num-totla {
        font-size: 16px;
        color: #fff;
      }
    }

    .classify-tip {
      font-family: DingTalk-JinBuTi;
      font-size: 17px;
      line-height: 17px;
      color: #ffffff;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
</style>